import ReactGA from "react-ga";

export const initGA = () => {
  console.log("GA init");
  // Must be Universal Analytics (UA-) code and NOT the newer Google Anayltics 4 (G-).
  // See https://stackoverflow.com/questions/64623059/google-analytics-4-with-react.
  ReactGA.initialize("UA-205129023-1");
};

export const logPageView = () => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// To confirm this works, go to Default Google Ads Profile > Reports > Real-time > Events.
export const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
